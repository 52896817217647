import { useEffect, useState } from "react";
import BaseCard from "../components/cards/BaseCard";
import Teams from "../components/Teams";
import { Datetime } from "../core/l10n";
import { Game } from "../types/game";
import "./Details.css";
import iconVarsity from "../assets/icons/varsity.svg";
import PurchaseForm from "../components/app/details/PurchaseForm";
import PlayerControl from "../components/app/details/PlayerControl";

type DetailsProps = Game & {
};

export default function Details({ id, home, away, venue, starts_at }: DetailsProps) {
  const [ hasBought, setHasBought ] = useState(false);

  useEffect(() => {
    // TEMP
    setHasBought(true);
  }, []);

  return (
    <div className="Details">
      <Teams
        home={home}
        away={away}
        />

      <p className="Details-datetimevenue">
        {starts_at.local().format(Datetime.FULL)}
        <br/>
        {venue}
      </p>

      <a href="https://thevarsitynetwork.com" className="button button--white">
        <img
          src={iconVarsity}
          alt="The Varsity Network"
          className="button-icon"
          />
        <span>View this game on The Varsity Network</span>
      </a>

      {hasBought
        ? <BaseCard innerStyle="Details-player">
            <PlayerControl
              gameId={id}
              />
          </BaseCard>
        : <BaseCard innerStyle="Details-form">
            <PurchaseForm
              gameId={id}
              onPurchased={() => setHasBought(true)}
              />
          </BaseCard>
      }
    </div>
  );
}